export default {
  init() {
    // JavaScript to be fired on the home page

    $('#quote-slider').slick({
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      mobileFirst: true,
      responsive: [{
        breakpoint: 767,
        settings: {
          arrows: true,
          prevArrow: '<button type="button" class="quote-prev"><svg class="h-12 w-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"/></svg></button>',
          nextArrow: '<button type="button" class="quote-next"><svg class="h-12 w-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg></button>',
        }
      }],
    });

    // customUpload('3_16');

    $(document).on('gform_post_render', function() {
      customUpload('3_16');
    })

    function customUpload(field) {
      $('#field_' + field + ' label').wrapInner('<div class="form-text"></div>');
      $('#field_' + field + ' label').prepend('<div class="h-8 w-8 mr-3"><svg class="form-upload h-8 w-8 fill-current text-c-lime" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path class="form-path" d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"/></svg></div>');
    
      $('#input_' + field).change(function(e) {
        $('.form-path').attr("d", "M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-11H5v2h10V9z")
        $('#field_' + field + ' svg').addClass('remove');
        $(".form-text").text(e.target.files[0].name)

        if($('#field_' + field + ' svg').hasClass('remove')) {
          $('#field_' + field + ' svg').click(function(e) {
            e.preventDefault();
            $('#input_' + field).val('');
            $(".form-text").text('Attach an image (optional)');
            $('.form-path').attr("d", "M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z")
            $('#field_' + field + ' svg').removeClass('remove');
            $(this).unbind('click').click()
          })
        }
      });
    }
  

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
