import 'selectize';

export default {
  init() {
    // JavaScript to be fired on all pages
    // $('input[type=checkbox]').addClass('form-checkbox')
    // customUpload('1_7');
    // customMulti('1_10');
    // customUpload('2_17');

    $(document).on('gform_post_render', function() {
      $('input[type=checkbox]').addClass('form-checkbox');
      customUpload('1_7');
      customMulti('1_10');
      customMulti('2_17');
    })

    function customUpload(field) {
      $('#field_' + field + ' label').wrapInner('<div class="form-text"></div>');
      $('#field_' + field + ' label').prepend('<div class="h-8 w-8 mr-3"><svg class="form-upload h-8 w-8 fill-current text-c-lime" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path class="form-path" d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"/></svg></div>');
    
      $('#input_' + field).change(function(e) {
        $('.form-path').attr("d", "M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-11H5v2h10V9z")
        $('#field_' + field + ' svg').addClass('remove');
        $(".form-text").text(e.target.files[0].name)

        if($('#field_' + field + ' svg').hasClass('remove')) {
          $('#field_' + field + ' svg').click(function(e) {
            e.preventDefault();
            $('#input_' + field).val('');
            $(".form-text").text('Attach official company logo (optional)');
            $('.form-path').attr("d", "M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z")
            $('#field_' + field + ' svg').removeClass('remove');
            $(this).unbind('click').click()
          })
        }
      });
    }

    function customMulti(field) {
      var $select = $('#input_' + field).selectize({
        plugins:['remove_button'],
      });
      $('#field_' + field + ' label').wrapInner('<span class="form-text-select"></span>');
      $('#field_' + field + ' label').prepend('<div class="h-8 w-8 mr-3"><svg class="form-upload h-8 w-8 fill-current text-c-lime" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path class="form-path-select" d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"/></svg></div>');
      
      $('#field_' + field + ' label').click(function(e) {
        //console.log('CLicked');
        if($('#field_' + field + ' svg').hasClass('remove')) {
          //console.log('Got Here');
          //console.log(select);
          var selectize = $select[0].selectize;
          selectize.clear();
          $('#field_' + field + ' .ginput_container_multiselect').hide();
          $(".form-text-select").show();
          $('.form-path-select').attr("d", "M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z")
          $('#field_' + field + ' svg').removeClass('remove');
        }
        else {
          $('.form-path-select').attr("d", "M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-11H5v2h10V9z")
          $('#field_' + field + ' svg').addClass('remove');
          $(".form-text-select").hide();
          $('#field_' + field + ' .ginput_container_multiselect').show();
        }
      })

    }

    


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
