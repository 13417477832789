export default {
  init() {
    // JavaScript to be fired on all pages
        // JavaScript to be fired on all pages
        $('input[type=checkbox]').addClass('form-checkbox');

        // $('.pledge-btn').click(function() {
        //   informz_trk('trackStructEvent',
        //   'Clicked',        // Category (Required, use only: Letters, Numbers & Spaces )
        //   'Pledge Button' // Item (Required, use only: Letters, Numbers & Spaces)
        //   )
        // })
        function tagFilters() {
          var url = location.protocol + '//' + location.host + location.pathname,
            args = {};
          
          var curr = window.location.href;
          var parseUrl = new URL(curr);
  
          url = url.split('page/')[0];
      
          // loop over filters
          $('.tax-filters .filters').each(function () {
            // vars
            var filter = $(this).data('filter');
            var vals = [];
      
            //console.log(filter)
      
            // find checked inputs
            $(this)
              .find('input:checked')
              .each(function () {
                //console.log($(this).val())
                vals.push($(this).val());
              });
      
            //console.log(vals)
      
            // append to args
            args[filter] = vals.join(',');
            //console.log(args)
          });
      
          // update url
          url += '?';
      
          // loop over args
          $.each(args, function (name, value) {
            if (value) {
              url += name + '=' + value + '&';
            }
          });
      
          // remove last &
          url = url.slice(0, -1);
      
          //console.log(url)
          // reload page
          window.location.replace(url);
        }

        $('.filter-submit').on('click', function() {
          tagFilters();
        });

        $('.dl-dei-all').on('click', function(e) {
          //console.log('click');
          e.preventDefault();
          //console.log(linkSource);
          var downloadLink = document.createElement("a");
          var fileName = 'AGC_DEI_All.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          //$(this).click();
        })

        $('.dl-dei-query').on('click', function(e) {
          //console.log('click');
          e.preventDefault();
          //console.log(linkSource);
          var downloadLink = document.createElement("a");
          var fileName = 'AGC_DEI_Filtered.xlsx';

          downloadLink.href = resultSource;
          downloadLink.download = fileName;
          downloadLink.click();
          //$(this).click();
        })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
