// import external dependencies
import 'jquery';
import 'lity';
import 'slick-carousel/slick/slick.min';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
 
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()


// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import commit from './routes/commit';
import templateCommitment from './routes/templateCommitment';
import templateStory from './routes/templateStory';
import templateImpact from './routes/templateImpact';
import singleState from './routes/singleState';
import impactChampions from './routes/impactChampions';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  commit,
  templateCommitment,
  templateStory,
  templateImpact,
  singleState,
  impactChampions,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
