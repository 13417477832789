export default {
  init() {
    // JavaScript to be fired on all pages
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var cat = getParameterByName('impact_state');
    if(cat !== null) {
      $('#state-select').val(cat);
    }

    


    $('#state-select').on('change', function() {
      if($(this).val() === 'ALL') {
        var url = '/impact-champions/'
      }
      else {
        var url = '/impact-champions/?impact_state=' + $(this).val();
      }
      window.location = url;
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
