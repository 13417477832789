export default {
  init() {
    // JavaScript to be fired on the home page
    if(window.innerWidth < 768) {
      mobileSlider();
    }

    function mobileSlider() {
      $('.slider').slick({
        dots: true,
        arrows: false,
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
      });
    }

    $(window).resize(function(e){
      if(window.innerWidth < 768) {
          if(!$('.slider').hasClass('slick-initialized')){
              mobileSlider();
          }
      }else{
        if($('.slider').hasClass('slick-initialized')){
            $('.slider').slick('unslick');
        }
      }
  });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
