export default {
  init() {

    // simple function to use for callback in the intersection observer
    //$('.impact-nav nav ul li a:first').addClass('active');

    const changeNav = (entries, observer) => {
      entries.forEach((entry) => {
        // verify the element is intersecting
        //console.log(entry);
        // if(entry.isIntersecting && entry.intersectionRatio >= 0.55) {
        //   // remove old active class
        //   if($('.impact-nav ul li a.active').hasClass('active')) {
        //     document.querySelector('div.impact-nav ul li a.active').classList.remove('active');
        //   }
        //   // get id of the intersecting section
        //   var id = entry.target.getAttribute('id');
        //   //console.log(id);
        //   // find matching link & add appropriate class
        //   var newLink = document.querySelector(`[href="#${id}"]`).classList.add('active');

          var id = entry.target.getAttribute('id');
          if (entry.isIntersecting) {
            $('.impact-nav nav ul li').removeClass('active')
            $(`[href="#${id}"]`).parent().addClass('active');
            //entry.target.classList.add('active');
          // Otherwise, remove the “active” class
          }
        // }
      });
    }

    // init the observer
    const options = {
      root: null,
      rootMargin: '-250px 0px -50%',
    }

    const observer = new IntersectionObserver(changeNav, options);

    // target the elements to be observed
    const sections = document.querySelectorAll('div.impact-section');
    //console.log(sections);
    sections.forEach((section) => {
      //console.log(section);
      observer.observe(section);
    });

    $("a[href*=\\#]:not([href=\\#])").click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
          || location.hostname == this.hostname) 
      {
        
        var target = $(this.hash),
        headerHeight = $(".banner").height() + 25; // Get fixed header height
              
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                
        if (target.length) 
        {
          $('html,body').animate({
            scrollTop: target.offset().top - headerHeight
          }, 'slow');
          return false;
        }
      }
    });

    //Modal observer to lock scroll
    var url = window.location.href;
    if(url.indexOf('?submit=true') != -1) {
      $('.form-modal').show();
      $('html, body').css({
          overflow: 'hidden',
      });
    }

    var observerModal = new MutationObserver(function(mutations) {
      $('html, body').css({
        overflow: 'visible',
      });
    });
    var target = document.querySelector('.modal_bg');
    observerModal.observe(target, {
      attributes: true
    });

    // $('.section-0 .sticker-2 .dl-group:eq(0)').click(function() {
    //   console.log('clicked');
    //   hardHat1();
    // })

    // $('.section-0 .sticker-1 .dl-group:eq(0)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Hard Hat 1' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-0 .sticker-1 .dl-group:eq(1)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Hard Hat 2' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-0 .sticker-1 .dl-group:eq(2)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Hard Hat 3' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-0 .sticker-1 .dl-group:eq(3)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Hard Hat 4' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })

    // $('.section-0 .sticker-2 .dl-group:eq(0)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Poster 1' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-0 .sticker-2 .dl-group:eq(1)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Poster 2' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-0 .sticker-2 .dl-group:eq(2)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Poster 3' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })

    // //Company Policies
    // $('.section-1 .split-0 a').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Company Policy 1' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-1 .split-1 a').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Company Policy 2' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })

    // //Educate
    // $('.section-2 .sticker-0 .dl-group:eq(0)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate Toolbox 1' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-2 .sticker-0 .dl-group:eq(1)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate Toolbox 2' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-2 .sticker-0 .dl-group:eq(2)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate Toolbox 3' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-2 .split-1 side:eq(0) a').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate Racism' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-2 .split-1 side:eq(1) a').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate Harassment' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-2 .split-2 side:eq(0) a').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'Educate CoC Training' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })

    // //COVID
    // $('.section-3 .text-content a:eq(0)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'COVID Tips' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-3 .sticker-1 .dl-group:eq(0)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'COVID Toolbox 1' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
    // $('.section-3 .sticker-1 .dl-group:eq(1)').click(function() {
    //   informz_trk('trackStructEvent',
    //   'Download',        // Category (Required, use only: Letters, Numbers & Spaces )
    //   'COVID Toolbox 2' // Item (Required, use only: Letters, Numbers & Spaces)
    //   );
    // })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
