export default {
  init() {
    
    // var offset = $(':target').offset();
    // console.log(offset)
    // var scrollto = offset.top - 150; // minus fixed header height
    // $('html, body').animate({scrollTop:scrollto}, 0);

    $("a[href*=\\#]:not([href=\\#])").click(function() 
    {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
        || location.hostname == this.hostname) 
    {
      
      var target = $(this.hash),
      headerHeight = $(".banner").height() + 25; // Get fixed header height
            
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              
      if (target.length) 
      {
        $('html,body').animate({
          scrollTop: target.offset().top - headerHeight
        }, 'slow');
        return false;
      }
    }
  });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
